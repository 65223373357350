import { Flex } from '@chakra-ui/react'
import { DiscussionEmbed } from 'disqus-react'
import { graphql } from 'gatsby'
import React from 'react'
import { CommentsList, PostEntry, Sidebar } from 'starterComponents'
import { Container } from 'starterUiComponents'
import { useThemeOptions } from '../../../context'
import { useLayoutStyles } from '../../utils/hooks'
const Post = (props) => {
  const {
    data: {
      post,
      post: {
        title,
        slug,
        template: { templateName },
      },
    },
    pageContext: { nextPagePath, previousPagePath },
  } = props

  const { containerStyles, sidebarSide, sidebarPage, sidebarWidgets } =
    useLayoutStyles('post', templateName.toLowerCase())

  const { disqus, addWordPressComments } = useThemeOptions()

  const disqusConfig = {
    shortname: disqus,
    config: { identifier: slug, title },
  }
  return (
    <Container
      page={post}
      type="post"
      className="mainContainer"
      sx={{ ...containerStyles }}
    >
      <Flex
        sx={{
          ...sidebarSide,
          flexWrap: { base: 'wrap', lg: 'nowrap' },
          alignItems: `flex-start`,
        }}
      >
        <PostEntry
          post={post}
          location="single"
          nextPagePath={nextPagePath}
          previousPagePath={previousPagePath}
        />

        {sidebarPage && (
          <Sidebar widgets={sidebarWidgets} className="md:mt-10" />
        )}
      </Flex>

      {addWordPressComments && post.commentStatus === 'open' && (
        <div>
          {disqus ? (
            <DiscussionEmbed {...disqusConfig} />
          ) : (
            <CommentsList post={post} />
          )}
        </div>
      )}
    </Container>
  )
}

export default Post

export const pageQuery = graphql`
  query WpPostDefaultTemplate($id: String!) {
    post: wpPost(id: { eq: $id }) {
      content
      commentStatus
      databaseId
      ...PostTemplateFragment_starter
    }
  }
`
